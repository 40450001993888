import Accordion from "components/dist/atoms/Accordion";
import Button from "components/dist/atoms/Button";
import Icon from "components/dist/atoms/Icon";
import Text from "components/dist/atoms/Text";
import { useGetLoanByIdQuery } from "src/services/loanApi";

type UploadingToastProps = {
    loanId: string;
};

type LoanState = 'REQUESTED' | 'IN_PROGRESS' | 'FINISHED' | 'FAILED' | 'NEVER';

type Loan = {
    zip: { state: LoanState };
    pdf: { state: LoanState };
    projectName: string;
};

const checkState = (loan: Loan | undefined, states: LoanState[]) => {
    return states.includes(loan?.zip.state) || states.includes(loan?.pdf.state);
};

export const GeneratePackageToast = ({ loanId }: UploadingToastProps) => {
    const { data: loan, error } = useGetLoanByIdQuery(loanId);

    if (error) {
        return <div role="alert" aria-label="Error Toast" className="flex flex-col gap-2">Error loading loan data</div>;
    }

    const isLoading = checkState(loan, ['REQUESTED', 'IN_PROGRESS']);
    const isGenerated = checkState(loan, ['FINISHED']);
    const isFailed = checkState(loan, ['FAILED']);
    const isNever = checkState(loan, ['NEVER']);
    const idle = isFailed || isNever || isGenerated;

    return (
        <div role="alert" aria-label="Uploading Toast" className="flex flex-col gap-2">
            <Accordion collapsible type="single" defaultValue="package-generating">
                <Accordion.Item value="package-generating">
                    <div className="flex gap-2 w-full items-center">
                        <Accordion.Trigger className="py-0" asChild onClick={(e) => e.stopPropagation()}>
                            <div className="flex gap-2 flex-1 justify-start py-0 items-center">
                                <Text size="sm">Generating Loan Package</Text>
                            </div>
                        </Accordion.Trigger>
                        {idle && (
                            <Button size="custom" variant="ghost" className="items-center flex rounded-md text-black-dark">
                                <Icon strokeWidth={1.5} name="Cancel" width={20} height={20} />
                            </Button>
                        )}
                    </div>
                    <Accordion.Content onClick={(e) => e.stopPropagation()}>
                        <div className="flex flex-row gap-2 py-4 items-center">
                            {isLoading && (
                                <Icon
                                    name="Refresh"
                                    width={16}
                                    height={16}
                                    strokeWidth={1.5}
                                    className="animate-spin text-green-flowkit [&>_path:nth-child(2)]:hidden"
                                />
                            )}
                            {(isGenerated && !isLoading) && (
                                <Icon
                                    name="Check"
                                    className="bg-green-flowkit text-white rounded-full"
                                    width={14}
                                    height={14}
                                    strokeWidth={1.5}
                                />
                            )}
                            <Icon name="Suitcase" width={20} className="text-black-primary" height={20} strokeWidth={1.5} />
                            <Text truncate size="sm" variant={isLoading ? "secondary" : "primary"}>
                                {loan?.projectName} Loan Package...
                            </Text>
                        </div>
                    </Accordion.Content>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};
